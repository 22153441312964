import "../../App.css";
import React from "react";

import RoadmapIMG from "../../assets/Roadmap.webp";

function Roadmap() {
  return (
    <div className="Roadmap" id="Roadmap">
      <img src={RoadmapIMG} className="RoadmapImg" />
    </div>
  );
}

export default Roadmap;
