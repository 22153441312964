import DiscordLogo from "../../assets/DiscordLogo.webp";
import OpenseaLogo from "../../assets/OpenseaLogo.webp";
import TwitterLogo from "../../assets/TwitterLogo.webp";
import EtherScanLogo from "../../assets/etherscanLogo.webp";
import Logo from "../../assets/RLogo.webp";
import GlitchesLogo from "../../assets/GlitchLogo.webp";

function Footer(props) {
  return (
    <footer className="App-Footer">
      <div className="Footer-left">
        <a href="/"><img src={Logo} className="FooterApp-logo" alt="logo" /></a>
      </div>
      <div className="Footer-center">
        <h4 className="FooterTitle">Contracts</h4>
        <div>
          <div>
            <img src={EtherScanLogo} className="footerlogo" alt="Etherscan Logo" />
            <a className="FooterText" href="https://etherscan.io/address/0x436f6a8e71F0c26b1690360166f6270021343AAA#code" target="blank">RobosNFT</a>
          </div>
          <div>
            <img src={EtherScanLogo} className="footerlogo" alt="Etherscan Logo" />
            <a className="FooterText" href="https://etherscan.io/address/0xbE8f69c0218086923aC35fb311A3dD84baB069E5#code" target="blank"> $CLANK!   </a>
          </div>
          {/* <div>
            <img src={GlitchesLogo} className="GlitchesLogo" alt="Glitch Logo" />
          </div> */}
        </div>
      </div>
      <div className="Footer-right">
        <h4 className="FooterTitle">Socials</h4>
        <div>
          <a target="_blank" href="https://twitter.com/pxRobos">
            <img src={TwitterLogo} className="footerlogo" alt="Twitter Logo" />
          </a>
          <a target="_blank" href="https://twitter.com/pxRobos" className="FooterText">
            Twitter
          </a>
        </div>
        <div>
          <a target="_blank" href="https://opensea.io/">
            <img src={OpenseaLogo} className="footerlogo" alt="Opensea Logo" />
          </a>
          <a target="_blank" href="https://opensea.io/collection/robosnft" className="FooterText">
            OpenSea
          </a>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
