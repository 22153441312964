import React, { useState } from "react";
import { useBalance } from "eth-hooks";

const { utils } = require("ethers");

export default function Balance(props) {
  const [dollarMode, setDollarMode] = useState(false);

  const balance = useBalance(props.provider, props.address);
  let floatBalance = parseFloat("0.00");
  let usingBalance = balance;

  if (typeof props.balance !== "undefined") usingBalance = props.balance;
  if (typeof props.value !== "undefined") usingBalance = props.value;

  if (usingBalance) {
    const etherBalance = utils.formatEther(usingBalance);
    parseFloat(etherBalance).toFixed(2);
    floatBalance = (parseFloat(etherBalance));
  }

  let displayBalance = "Ξ " + (floatBalance.toFixed(4));

  const price = props.price || props.dollarMultiplier || 1;

  if (dollarMode) {
    displayBalance = "$ " + (floatBalance * price).toFixed(2);
  }

  return (
    <span
      style={{
        verticalAlign: "top",
        fontSize: props.size ? props.size : 20,
        padding: 8,
        cursor: "pointer",
        color: "#fff",
      }}
      onClick={() => {
        setDollarMode(!dollarMode);
      }}
    >
      {displayBalance}
    </span>
  );
}
