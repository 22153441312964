import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "../../App.css";

import robosPaper from "../../assets/Robopaper.pdf";

export default function ControlledAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
    <h1 className="TeamTitle">FAQ's</h1>
    <div className="accordion-page">
      <div className="accordion">
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{
              bgcolor: "#2c0280", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography
              sx={{ width: "100%", flexShrink: 20 }}
              className="questions"
            >
              What is the supply in the Robos Genesis Collection?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: "#2c0280e1", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography className="anwsers">
              2222 Genesis Robos will be available to mint.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel2bh-header"
            sx={{
              bgcolor: "#2c0280", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography
              className="questions"
              sx={{ width: "100%", flexShrink: 0 }}
            >
              What are the Robos?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: "#2c0280e1", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography className="anwsers">
            The Genesis Robos NFT collection comprises of 2,222 Ethereum blockchain warriors, hand picked to lead a resistance against the tyrannical intergalactic leader Dolnance Federv, and his evil space brigade. 
The chosen Robos have retreated to a new world called DeEarth AstroAutonomous Orgus. There they must grow their numbers, bargain with local inhabitants for resources, and build new technologies to fight back against their aggressors.
            </Typography>
          </AccordionDetails>
        </Accordion>
        
        <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            sx={{
              bgcolor: "#2c0280", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography
              sx={{ width: "100%", flexShrink: 0 }}
              className="questions"
            >
              What is the Robopaper V1?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: "#2c0280e1", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography className="anwsers">
              The RoboPaper will provide a clearer in-depth of our objectives on the <a href={robosPaper}  target = "_blank">Robos Roadmap</a>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            sx={{
              bgcolor: "#2c0280", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography
              sx={{ width: "100%", flexShrink: 0 }}
              className="questions"
            >
              What is $CLANK?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: "#2c0280e1", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography className="anwsers">
              $CLANK is the utility token of Robos. More information on the utility of $CLANK will be provided in the <a href={robosPaper}  target = "_blank">Robopaper V1
                </a>.
            </Typography>
          </AccordionDetails>
        </Accordion>
       
        <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            sx={{
              bgcolor: "#2c0280", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography
              sx={{ width: "100%", flexShrink: 0 }}
              className="questions"
            >
              What is the Mint Price?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: "#2c0280e1", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography className="anwsers">
             Free Mint
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4bh-content"
            id="panel4bh-header"
            sx={{
              bgcolor: "#2c0280", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography
              sx={{ width: "100%", flexShrink: 0 }}
              className="questions"
            >
              When was mint?
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              bgcolor: "#2c0280e1", // use summary background color
              color: "white", // use summary default color
            }}
          >
            <Typography className="anwsers">
            July 2nd
            </Typography>
          </AccordionDetails>
        </Accordion>
       </div>
    </div>
    </>
  );
}