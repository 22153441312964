import "../../App.css";
import React from "react";

import XurgisLogo from "../../assets/XurgLogo.webp";
import ZedsLogo from "../../assets/predator.webp";
import IceyLogo from "../../assets/IceyLogo.webp";
import DadlessLogo from "../../assets/CyperPunk.webp";
import DrizzyLogo from "../../assets/DrizzyLogo.webp";
import BuzzLogo from "../../assets/BuzzLogo.webp";
import ClankBot from '../../assets/ClankBot.webp'

function Team() {
  return (
    <>
    <h1 className="TeamTitle">Meet the Team!</h1>
    <div className="RobosTeam">
        <article class="card">
          <div class="card-header">
            <h2>Main Advisor</h2>
          </div>
          <div class="card-author">
            <p class="author-avatar">
              <img src={XurgisLogo} alt="loglogo" />
            </p>
            <div class="author-name">
              <div class="author-name-prefix"></div>
                Xurg
            </div>
          </div>
          <div class="tags">
            <a target="blank" href="https://twitter.com/xurgxurg">Twitter</a>
          </div>
        </article>


        <article class="card">
          <div class="card-header">
            <h2>Project Developer</h2>
          </div>

          <div class="card-author">
            <p class="author-avatar">
              <img src={DadlessLogo} className="Team-logo" alt="loglogo" />
            </p>

            <div class="author-name">
              <div class="author-name-prefix">0xOrphan</div>
              DadlessNsad
            </div>
          </div>
          <div class="tags">
            <a target="blank" href="https://twitter.com/0xOrphan">Twitter</a>
          </div>
        </article>

        <article class="card">
          <div class="card-header">
            <h2>Project Artist</h2>
          </div>

          <div class="card-author">
            <p class="author-avatar">
              <img src={ZedsLogo} className="author-avatar" alt="loglogo" />
            </p>

            <div class="author-name">
              <div class="author-name-prefix">The Legend</div>
              Zed
            </div>
          </div>
          <div class="tags">
            <a target="blank" href="https://twitter.com/Zed_nft">Twitter</a>
          </div>
        </article>

      </div>

      <div className="RobosTeam">
      <article class="card">
          <div class="card-header">
            <h2>Mr. Clank Bot</h2>
          </div>
          <div class="card-author">
            <p class="author-avatar">
              <img src={ClankBot} alt="loglogo" />
            </p>
            <div class="author-name">
              <div class="author-name-prefix">!CLANK</div>
              !CLANK
            </div>
          </div>
          <div class="tags">
            <a target="
            
            
            
            
            
            blank" href="https://twitter.com/theclankbot?s=21&t=bGGYxHPwT8aost6zCF5PdA">Twitter</a>
          </div>
        </article>

        <article class="card">
          <div class="card-header">
            <h2>Community Manager</h2>
          </div>
          <div class="card-author">
            <p class="author-avatar">
              <img src={IceyLogo} alt="loglogo" />
            </p>
            <div class="author-name">
              <div class="author-name-prefix"></div>
              Icey
            </div>
          </div>
          <div class="tags">
            <a target="_blank" href="https://twitter.com/iceycrypto">Twitter</a>
          </div>
        </article>

        <article class="card">
          <div class="card-header">
            <h2>Project Lead</h2>
          </div>
          <div class="card-author">
            <p class="author-avatar" >
              <img src={BuzzLogo} alt="loglogo" />
            </p>
            <div class="author-name">
              <div class="author-name-prefix"></div>
              Buzz 🐝
            </div>
          </div>
          <div class="tags">
            <a target='_blank' href="https://twitter.com/BuzzNFTss">Twitter</a>
          </div>
        </article>

        <article class="card">
          <div class="card-header">
            <h2>Social Media Manager</h2>
          </div>

          <div class="card-author">
            <p class="author-avatar" >
              <img src={DrizzyLogo} className="author-avatar" alt="loglogo" />
            </p>

            <div class="author-name">
              <div class="author-name-prefix">Collab Manager</div>
              Drizzy
            </div>
          </div>
          <div class="tags">
            <a target='_blank' href="https://twitter.com/drizzysfamily?s=21&t=bGGYxHPwT8aost6zCF5PdA">Twitter</a>
          </div>
        </article>
      </div>
    </>
  );
}
export default Team;
