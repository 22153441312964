import { Col, Row } from "antd";
import "antd/dist/antd.css";
import { useContractLoader, useGasPrice, useOnBlock, useUserProviderAndSigner } from "eth-hooks";
import { useExchangeEthPrice } from "eth-hooks/dapps/dex";
import React, { useCallback, useEffect, useState } from "react";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import "./App.css";
import { Account, GasGauge, Ramp, NetworkDisplay, NetworkSwitch } from "./components";
import { NETWORKS, ALCHEMY_KEY } from "./constants";
import externalContracts from "./contracts/external_contracts";

// contracts
import deployedContracts from "./contracts/hardhat_contracts.json";
import { Web3ModalSetup } from "./helpers";
import { useStaticJsonRPC } from "./hooks";
import ControlledAccordions from "./components/Faq/Faq";
import Roadmap from "./components/Roadmap/Roadmap";
import Team from "./components/Team/Team";
import Footer from "./components/Footer/Footer";

//assets
import Backgorund from "./assets/Background_Animation.webm";
import Logo from "./assets/RLogo.webp";
import LandingLogo from "./assets/RobosLogo.webp";
import RobosNFT from "./contracts/RobosNFT.json";
import BoltsToken from "./contracts/ClankToken.json";
import MintLogo from "./assets/2.webp";
import Ticker from "./components/Ticker/Ticker";
import Market from "./assets/bg.gif"
const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");

const { ethers } = require("ethers");

const initialNetwork = NETWORKS.mainnet;
const NETWORKCHECK = true;
const USE_BURNER_WALLET = false;
const USE_NETWORK_SELECTOR = false;
const web3Modal = Web3ModalSetup();
// 🛰 providers
const providers = [
  "https://eth-mainnet.gateway.pokt.network/v1/lb/611156b4a585a20035148406",
  `https://eth-mainnet.alchemyapi.io/v2/${ALCHEMY_KEY}`,
  "https://rpc.scaffoldeth.io:48544",
];

function App(props) {
  const [mintAmount, setMintAmount] = useState(1);
  const networkOptions = [initialNetwork.name, "mainnet"];
  const [injectedProvider, setInjectedProvider] = useState();
  const [address, setAddress] = useState();
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalMinted, setTotalMinted] = useState(0);
  const [loading, setLoading] = useState(true);
  const [roboOwner, setRoboOwner] = useState();
  const [claimableClank, setClaimableClank] = useState(0);
  const [selectedNetwork, setSelectedNetwork] = useState(networkOptions[0]);
  const location = useLocation();
  const targetNetwork = NETWORKS[selectedNetwork];
  // 🔭 block explorer URL
  const blockExplorer = targetNetwork.blockExplorer;

  // load all your providers
  const localProvider = useStaticJsonRPC([
    process.env.REACT_APP_PROVIDER ? process.env.REACT_APP_PROVIDER : targetNetwork.rpcUrl,
  ]);
  const mainnetProvider = useStaticJsonRPC(providers);

  const logoutOfWeb3Modal = async () => {
    await web3Modal.clearCachedProvider();
    if (injectedProvider && injectedProvider.provider && typeof injectedProvider.provider.disconnect == "function") {
      await injectedProvider.provider.disconnect();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1);
  };

  const price = useExchangeEthPrice(
    targetNetwork,
    mainnetProvider,
  ); /* 💵 This hook will get the price of ETH from 🦄 Uniswap: */
  const gasPrice = useGasPrice(
    targetNetwork,
    "fast",
  ); /* 🔥 This hook will get the price of Gas from ⛽️ EtherGasStation */
  // Use your injected provider from 🦊 Metamask or if you don't have it then instantly generate a 🔥 burner wallet.
  const userProviderAndSigner = useUserProviderAndSigner(injectedProvider, localProvider);
  const userSigner = userProviderAndSigner.signer;

  useEffect(() => {
    async function getAddress() {
      if (userSigner) {
        const newAddress = await userSigner.getAddress();
        setAddress(newAddress);
      }
    }
    getAddress();
  }, [userSigner]);

  // You can warn the user if you would like them to be on a specific network
  const localChainId = localProvider && localProvider._network && localProvider._network.chainId;
  const selectedChainId =
    userSigner && userSigner.provider && userSigner.provider._network && userSigner.provider._network.chainId;

  const contractConfig = { deployedContracts: deployedContracts || {}, externalContracts: externalContracts || {} };
  const readContracts = useContractLoader(localProvider, contractConfig);
  const writeContracts = useContractLoader(userSigner, contractConfig, localChainId);

  const mainnetContracts = useContractLoader(mainnetProvider, contractConfig);

  useOnBlock(mainnetProvider, () => {
    console.log(`⛓ A new mainnet block is here: ${mainnetProvider._lastBlockNumber}`);
  });

  const loadWeb3Modal = useCallback(async () => {
    const provider = await web3Modal.connect();
    setInjectedProvider(new ethers.providers.Web3Provider(provider));

    provider.on("chainChanged", chainId => {
      console.log(`chain changed to ${chainId}! updating providers`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    provider.on("accountsChanged", () => {
      console.log(`account changed!`);
      setInjectedProvider(new ethers.providers.Web3Provider(provider));
    });

    // Subscribe to session disconnection
    provider.on("disconnect", (code, reason) => {
      console.log(code, reason);
      logoutOfWeb3Modal();
    });
    // eslint-disable-next-line
  }, [setInjectedProvider]);

  const ethValue = (mintAmount * 0.0).toFixed(2);
  const CONTRACT_ADDRESS = "0x436f6a8e71F0c26b1690360166f6270021343AAA";

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      loadWeb3Modal();
    }
  }, [loadWeb3Modal]);

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const handleMint = async event => {
    try {
      const contract = new ethers.Contract(CONTRACT_ADDRESS, RobosNFT.abi, injectedProvider);
      const signer = injectedProvider.getSigner(0);
      const contractSigner = contract.connect(signer);

      const ethValueString = ethValue.toString();
      console.log(ethValue.toString());

      const txParamsOverride = {
        value: ethers.utils.parseEther(ethValueString),
      };

      const tx = await contractSigner.mintGenesisRobo(mintAmount, txParamsOverride);
      await tx.wait();
    } catch (error) {
      alert(error.error.message);
      console.error(error);
    }
  };

  const handleWLMint = async event => {
    try {
      const contract = new ethers.Contract(CONTRACT_ADDRESS, RobosNFT.abi, injectedProvider);
      const signer = injectedProvider.getSigner(0);
      const contractSigner = contract.connect(signer);

      const ethValueString = ethValue.toString();
      console.log(ethValue.toString());

      let whitelistAddresses = require("./utils/whitelist.json");
      const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
      const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
      let rootHash = merkleTree.getHexRoot();
      console.log(rootHash);
      const claimingAddress = keccak256(address);
      const proof = merkleTree.getHexProof(claimingAddress);

      const txParamsOverride = {
        value: ethers.utils.parseEther(ethValueString),
      };

      const tx = await contractSigner.whitelistMint(proof, mintAmount, txParamsOverride);
      await tx.wait();
    } catch (error) {
      alert(error.error.message);
      console.error(error);
    }
  };
  
  useEffect(function () {
    async function fetchTotals() {
      if (!ethers) {
        console.log("Install MetaMask");
        setLoading(false);
        return;
      }

      await getBalanceOf();
      await getTotalSupply();
      await getClankGeneration();
      setLoading(false);
    }
    fetchTotals();
  });
  
  
  
  
  async function getBalanceOf() {
    try {
      const BOLTS_ADDRESS = "0xbE8f69c0218086923aC35fb311A3dD84baB069E5";
      const contract = new ethers.Contract(BOLTS_ADDRESS, BoltsToken.abi, injectedProvider);

      const balance = (await contract.balanceOf(address)).toString();
      const data = ethers.utils.formatEther(balance);

      setTotalBalance(parseFloat(data.toString()).toFixed(4));
    } catch (error) {}
  }

  async function getTotalSupply() {
    try {
      // Interact with contract
      const CONTRACT_ADDRESS = "0x436f6a8e71F0c26b1690360166f6270021343AAA";
      const contract = new ethers.Contract(CONTRACT_ADDRESS, RobosNFT.abi, injectedProvider);
      const data = await contract.balanceOf(address);

      if (data > 0) {
        setRoboOwner(true);
        setTotalMinted(data.toString());
      } else {
        setRoboOwner(false);
      }

    } catch (error) {
      console.log(error);
    }
  }

  async function getClankGeneration() {
    try {
      // Interact with contract

      const CONTRACT_ADDRESS = "0xbE8f69c0218086923aC35fb311A3dD84baB069E5";
      const contract = new ethers.Contract(CONTRACT_ADDRESS, BoltsToken.abi, injectedProvider);
      const claimable = await contract.rewards(address);
      const data = ethers.utils.formatEther(claimable);

      setClaimableClank(parseFloat(data.toString()).toFixed(4));
    } catch (error) {
      console.log(error);
    }
  }

  async function Claim() {
    try {
      const CONTRACT_ADDRESS = "0x436f6a8e71F0c26b1690360166f6270021343AAA";
      const contract = new ethers.Contract(CONTRACT_ADDRESS, RobosNFT.abi, injectedProvider);
      const signer = injectedProvider.getSigner(0);
      const contractSigner = contract.connect(signer);

      const claim = await contractSigner.getReward();

      await claim.wait();
      console.log(claim);
      alert(claim);
    } catch (error) {
      alert(error.error.message);
      console.error(error);
    }
  }

  const onDirect = (url) => {
    window.location.href = url
  }
  console.log(totalBalance);

  return (
    <div className="App">
      <NetworkDisplay
        NETWORKCHECK={NETWORKCHECK}
        localChainId={localChainId}
        selectedChainId={selectedChainId}
        targetNetwork={targetNetwork}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        USE_NETWORK_SELECTOR={USE_NETWORK_SELECTOR}
      />
      <video className="background" autoPlay loop muted playsinline >
        <source src={Backgorund}  type="video/webm" />
      </video>

      <header>
        <div>
          <a href="/" className="headerText">
            <img src={Logo} className="App-logo" alt="logo" />
          </a>

          <h1 className="headerText">
            <a href="#Roadmap" className="headerText">
              About
            </a>
          </h1>
          <h1 className="headerText">
            <a href="#" className="headerTextDisabled">
              Vote
            </a>
          </h1>
          <h1 className="headerText">
            <a to={"/myrobos"} className="headerTextDisabled">
              My-Robos
            </a>
          </h1>
          <h1 className="headerText"  onClick = {()=> onDirect("https://robosshop.com/")}>
            <a href={"https://robosshop.com/"} className="headerText">
            pxRobos Marketplace
            </a>
          </h1>
        </div>
        <div className="Wallet">
          <h1 className="headerTextbolts">$CLANK: {totalBalance} </h1>
          {USE_NETWORK_SELECTOR && (
            <div style={{ marginRight: 20 }}>
              <NetworkSwitch
                networkOptions={networkOptions}
                selectedNetwork={selectedNetwork}
                setSelectedNetwork={setSelectedNetwork}
              />
            </div>
          )}
          <Account
            useBurner={USE_BURNER_WALLET}
            address={address}
            localProvider={localProvider}
            userSigner={userSigner}
            mainnetProvider={mainnetProvider}
            price={price}
            web3Modal={web3Modal}
            loadWeb3Modal={loadWeb3Modal}
            logoutOfWeb3Modal={logoutOfWeb3Modal}
            blockExplorer={blockExplorer}
          />
        </div>
      </header>

      <div className="LandingPage">
        <img src={LandingLogo} alt="Landing Logo" className="LandingLogo" />
      </div>
      <div className="descCtn">
        <div className="desc">
          <h1 className="mintTitle">The pxRobos project has Sold Out.</h1>
          {roboOwner && (
            <div className="">
              <h1 className="mintTitle">Purchase a pxRobos on <a href='https://opensea.io/collection/robosnft' target='_blank'>OpenSea</a> </h1>
              <p className="UserCount">Robos Owned: {totalMinted}</p>
              <p className="UserClankCount">You automatically earn:
                <br/>3 $Clank per day for each Legendary pxRobos owned.
                <br/>2 $Clank per day for each Genesis pxRobos owned.
                <br/>1 $Clank per day for each Robos Junior owned.</p>
              <button className="ClaimButton" onClick={Claim}>CLAIM $CLANK</button>
            </div>
          )}
        </div>
      </div>

      <div className="marketCtn">
        <div className="market" onClick = {()=> onDirect("https://robosshop.com/")}>
          <img src={Market} alt="" className = "market-img"/>
        </div>
      </div>
          
      <Roadmap />
      <Team />
      <ControlledAccordions />
      <Footer />

      {/* 🗺 Extra UI like gas price, eth price, faucet, and support: */}
      <div style={{ position: "fixed", textAlign: "left", left: 0, bottom: 20, padding: 10 }}>
        <Row align="middle" gutter={[4, 4]}>
          <Col span={18}>
            <Ramp price={price} address={address} networks={NETWORKS} />
          </Col>
          <Col span={10} style={{ textAlign: "center", opacity: 0.8 }}>
            <GasGauge gasPrice={gasPrice} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default App;
